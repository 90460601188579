<template>
  <div
    @contextmenu="this.setContextIdWidget"
    :style="{
      height: this.widgetHeight + 'px',
      width: this.widgetWidth + 'px',
      background: darkDark !== 'white'  ? this.cardBackground : 'var(--widgetBg)',
      borderWidth: this.borderDisplay ? this.borderWidth + 'px' : '0px',
      'border-color': this.borderColor,
      'border-radius': '10px',
      overflow: 'hidden'
    }"
    class="light_widget-mode rounded-2xl shadow-lg "
    style="position: relative"
  >
    <div class="text-center __lable__div">
      <div class="tracking-wide text-center overflow-hidden">
        <p
          v-if="this.titleDisplay"
          :style="{
            fontFamily: 'Open Sans,sans-serif',
            fontSize: this.autoFit
              ? this.widgetWidth < this.widgetHeight
                ? this.widgetWidth * 3 + '%'
                : this.widgetHeight * 2.5 + '%'
              : (this.titleSize*10) + '%',
            background: this.titleBgColor,
            color: this.titleColor
          }"
          class="text-md"
          :class="[
            {
              __theme_color__lable__title: !this.titleColor,
              labelWidgetTxt: !this.titleSize,
            },
          ]"
        >
          {{ this.title || widgetTitle }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// import s from "../../../assets/styles/"
export default {
  name: "card-stats",

  props: {
    displayId: String,
    id: [Number, String],
    widgetHeight: {
      type: Number,
      default: 50,
    },
    widgetWidth: {
      type: Number,
      default: 130,
    },
    autoFit: {
      type: Boolean,
      default: true,
    },
    cardBackground: {
      type: String,
      default: "#3f3f3f",
    },
    borderWidth: {
      type: String,
      default: "1",
    },
    borderDisplay: {
      type: Boolean,
      default: false,
    },
    // borderColor: {
    //   type: String,
    //   default: "#fffff",
    // },
    titleDisplay: {
      type: Boolean,
      default: true,
    },
    titleSize: {
      type: Number,
      default: 45,
    },
    titleColor: {
      type: String,
      default:'var(--activeTextColor)'
    },
    titleBgColor: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Label",
    },
    widgetTitle: {
      type: String,
      default: "Label",
    },
    widgetId: {
      type: String,
      default: "WidgetId",
    },
    height: {
      type: Number,
      default: 200,
    },
    width: {
      type: Number,
      default: 350,
    },
  },
  mounted() {
    // let index = 0;
    // for (let prop of this.$store.state.prop.properties) {
    //   if (prop.displayId == this.displayId && prop.widgetId == this.widgetId) {
    //     this.widgetPropertyIndex = index;
    //     continue;
    //   }
    //   index++;
    // }
  },
  methods: {
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
      // e.stopPropagation();
    },
    dark() {
      this.$emit("dark");
    },

    light() {
      this.$emit("light");
    },
  },
  computed: {
    darkDark() {
      if (this.$store.state.rect.darkmode !== 'white') this.borderColor = "#FFFFFF";
      else this.borderColor = "#000000";
      return this.$store.state.rect.darkmode;
    },
  },
  data() {
    return {
      widgetPropertyIndex: -1,
      borderColor: "",
    };
  },
};
</script>

<style scopped>
@import url("https://fonts.googleapis.com/css2?family=Crimson+Text:ital@1&display=swap");

.bg_dark_purple {
  background: #1d1e22;
}

.cust_shadow {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 90%),
    0 4px 6px -2px rgb(0 0 0 / 92%);
}

.cust_shadow_2 {
  --tw-shadow: 0 10px 15px -3px #1d1e22, 0 4px 6px -2px #1d1e22;
}

.shadow_out {
  --tw-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 90%),
    0 4px 6px -2px rgb(0 0 0 / 92%);
}

.bg_purple {
  background: #28282f;
  /* margin:10px ; */
  /* #1d1e22 */
  /* #2aa77c */
  /* #3e3c6b */
  /* #32344f */
}

.bg_green {
  color: #2aa77c;
}

.light_widget-mode {
  /* #fcffe2; */
  /* #fff8e4; */
  /* #ffffe7; */
  /* #fcfbda; */
  /* #fffff0 */
  background: var(--navBar2Bg) !important;
  transition: var(--transition);
  /* border: 0px !important; */
  border-radius: 0px !important;
  font-weight: 600 !important;
  /* box-shadow: none !important; */
}

.labelWidgetTxt {
  font-size: 12px !important;
}

.__theme_color__lable__title {
  /* color: var(--activeTextColor) !important; */
}

/* .cust_font{
  font-family: 'Crimson Text', serif;
} */

/* .truncates{
   display: block;
  white-space: nowrap;
  width: 12em;
  overflow: hidden;
  text-overflow:clip;
  color: red;
} */

.__lable__div {
  position: absolute;
  top: 50%;
  width: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
